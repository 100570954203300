import request from '../../utils/request'
// const token=localStorage.getItem("access_token");
// console.log('token',token)

//根据查询条件获取用户列表
export function userList(hotelId,status,userName,userType,limit,page,token) {
    return request({
        url:"/hotel/user/pageList",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId,
            status:status,
            userName:userName,
            userType:userType,
            limit:limit,
            page:page,
        }
    })
}

//新增用户
export function addUser(data,token){
    return request({
        url:"/hotel/user/add",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//获取酒店列表
// export function hotelList(token){
//     return request({
//         url:"/hotel/hotel/getAllHotel",
//         method:"GET",
//         headers:{
//             'Authorization':'Bearer ' + token
//         },
//     })
// }
//获取房间列表
export function roomList(hotelId,token){
    return request({
        url:"/hotel/listRoomByHotel",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            hotelId:hotelId
        }
    })
}
//获取用户详情
export function userDetail(userId,hotelId,token){
    return request({
        url:"/hotel/user/getDetails",
        method:"GET",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            userId:userId,
            hotelId:hotelId
        }
    })
}

//修改用户
export function editUser(data,token){
    return request({
        url:"/hotel/user/update",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:data
    })
}
//删除用户
export function delUser(userId,hotelId,token){
    return request({
        url:"/hotel/user/remove",
        method:"POST",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            userId:userId,
            hotelId:hotelId
        }
    })
}
